import { useState } from 'react'
import { Link, useSearchParams, useLocation } from 'react-router-dom'
import { gql } from 'graphql-request'

import { useQuery } from '@hooks/graphql'
import { formatTimestamp } from '@helpers/format'
import PageHeading from '@components/PageHeading'
import Table from '@components/Table'
import Pagination from '@components/Pagination'
import SearchInput from '@components/SearchInput'
import Spinner from '@components/Spinner'
import Select from '@components/Select'
import { startCase } from 'lodash'
import NoResults from './NoResults'

const ORGANIZATIONS_QUERY = gql`
  query organizations($page: Int!, $search: String, $accountStatus: AccountStatusEnum) {
    organizations(page: $page, perPage: 10, search: $search, accountStatus: $accountStatus) {
      nodes {
        id
        name
        createdAt
        accountStatus
      }
      pagesCount
    }
  }
`

const OrganizationList = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation()
  const page = Number(searchParams.get('page')) || 1
  const search = searchParams.get('search') || ''
  const accountStatus = searchParams.get('accountStatus') || ''
  const [searchTerm, setSearchTerm] = useState(search)

  const { data: { organizations: { nodes: organizations = [], pagesCount } = {} } = {}, isLoading } = useQuery({
    queryKey: ['organizations', page, search, accountStatus],
    gqlQuery: ORGANIZATIONS_QUERY,
    variables: { page, search, accountStatus: accountStatus || null }
  })

  const updateParams = newParams => {
    const params = {
      page,
      search,
      accountStatus,
      ...newParams
    }

    if (params.page === 1) delete params.page
    if (params.search === '') delete params.search
    if (params.accountStatus === '') delete params.accountStatus

    setSearchParams(params)
  }

  const clearFilters = () => {
    setSearchTerm('')
    setSearchParams({})
  }

  const clearSearch = () => {
    setSearchTerm('')
    updateParams({ search: '', page: 1 })
  }

  return (
    <>
      <PageHeading title='Organizations' />

      <div className='m-5'>
        <div className='flex gap-3 mb-5'>
          <SearchInput
            className='flex-1'
            value={searchTerm}
            onChange={event => setSearchTerm(event.target.value)}
            onSearch={() => updateParams({ search: searchTerm, page: 1 })}
            onClear={clearSearch}
          />

          <Select
            id='status-filter'
            value={accountStatus}
            onChange={e => updateParams({ accountStatus: e.target.value, page: 1 })}
            className='[&>*:last-child]:h-10'
            placeholder='Filter by status'
          >
            <Select.Option value=''>Select account status</Select.Option>
            <Select.Option value='ACTIVE'>Active</Select.Option>
            <Select.Option value='TRIAL'>Trial</Select.Option>
            <Select.Option value='DORMANT'>Dormant</Select.Option>
            <Select.Option value='TEST_DATA'>Test Data</Select.Option>
          </Select>
        </div>

        <Choose>
          <When condition={isLoading}>
            <Spinner className='flex justify-center mt-64' />
          </When>

          <When condition={organizations.length < 1}>
            <NoResults clearFilters={clearFilters} />
          </When>

          <Otherwise>
            <Table>
              <Table.Head>
                <Table.Row>
                  <Table.Header width='40%'>Name</Table.Header>
                  <Table.Header width='30%'>Created at</Table.Header>
                  <Table.Header width='30%'>Status</Table.Header>
                </Table.Row>
              </Table.Head>

              <Table.Body>
                <For each='organization' of={organizations}>
                  <Table.Row key={organization.id}>
                    <Table.Cell>
                      <Link
                        className='block'
                        to={`${organization.id}`}
                        state={{ previousListParams: location.search }}
                      >
                        {organization.name}
                      </Link>
                    </Table.Cell>

                    <Table.Cell>
                      <Link
                        className='block'
                        to={`${organization.id}`}
                        state={{ previousListParams: location.search }}
                      >
                        {formatTimestamp(organization.createdAt)}
                      </Link>
                    </Table.Cell>

                    <Table.Cell>
                      <Link
                        className='block'
                        to={`${organization.id}`}
                        state={{ previousListParams: location.search }}
                      >
                        <span className={`inline-flex items-center rounded-md px-2 py-1 text-sm font-medium ${!organization.accountStatus ? 'bg-gray-50 text-gray-700' :
                          organization.accountStatus === 'ACTIVE' ? 'bg-green-50 text-green-700' :
                            organization.accountStatus === 'TRIAL' ? 'bg-purple-50 text-purple-700' :
                              organization.accountStatus === 'DORMANT' ? 'bg-red-50 text-red-700' :
                                organization.accountStatus === 'TEST_DATA' ? 'bg-gray-50 text-gray-700' :
                                  'bg-gray-50 text-gray-700'
                          }`}>
                          {!organization.accountStatus ? 'NONE' : startCase(organization.accountStatus)}
                        </span>
                      </Link>
                    </Table.Cell>
                  </Table.Row>
                </For>
              </Table.Body>
            </Table>

            <Pagination page={page} pagesCount={pagesCount} setPage={page => updateParams({ page })} />
          </Otherwise>
        </Choose>
      </div>
    </>
  )
}

export default OrganizationList
