import { gql } from 'graphql-request'
import { useParams, useSearchParams, Link } from 'react-router-dom'
import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import { startCase } from 'lodash'

import { useQuery } from '@hooks/graphql'
import { formatTimestamp } from '@helpers/format'
import Card from '@components/Card'
import Table from '@components/Table'
import Pagination from '@components/Pagination'
import Spinner from '@components/Spinner'
import { countries } from '@components/PhoneNumber'

import NoResults from './NoResults'

const PROFILE_QUERY = gql`
  query adminProfile($id: ID!) {
    adminProfile(id: $id) {
      id
      fullName
      email
      phoneCountryCode
      phoneNumber
      onboardingCompleted
      organizationRole
      trialStartedAt
      trialLength
    }
  }
`

const CLASSROOMS_QUERY = gql`
  query adminClassrooms($profileId: ID!, $organizationId: ID!, $page: Int, $perPage: Int) {
    adminClassrooms(profileId: $profileId, organizationId: $organizationId, page: $page, perPage: $perPage) {
      nodes {
        id
        name
        archived
        studentsCount
        pendingInvitationsCount
      }
      pagesCount
      nodesCount
    }
  }
`

const Item = ({ label, value }) => (
  <div className='px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
    <dt className='font-medium'>{label}</dt>
    <dd className='mt-1 leading-6 text-gray-700 sm:col-span-2 sm:mt-0'>{value}</dd>
  </div>
)

const EducatorDetails = ({ organizationId }) => {
  const { id } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()

  const page = parseInt(searchParams.get('page')) || 1
  const setPage = page => setSearchParams({ page: page.toString() })

  const { isLoading: isProfileLoading, data: { adminProfile: profile = {} } = {} } = useQuery({
    queryKey: ['adminProfile', id],
    gqlQuery: PROFILE_QUERY,
    variables: { id }
  })

  const { isLoading: isClassroomsLoading, data: { adminClassrooms: { nodes: classrooms = [], pagesCount, nodesCount } = {} } = {} } = useQuery({
    queryKey: ['adminClassrooms', id, organizationId, page],
    gqlQuery: CLASSROOMS_QUERY,
    variables: { profileId: id, organizationId, page, perPage: 10 }
  })

  const dialCode = countries.find(country => country.code === profile.phoneCountryCode)?.dialCode

  if (isProfileLoading) return

  return (
    <div className='sm:px-5 pt-5 mb-5'>
      <Link to={`/admin/organizations/${organizationId}/educators`} className='flex text-base mb-5 text-blue-500 hover:text-gray-900'>
        <ArrowLeftIcon className='size-5 mr-1' />
        Back to educators
      </Link>

      <Card className='w-auto mb-5'>
        <div className='px-4 py-6 sm:px-6'>
          <h3 className='text-2xl font-semibold leading-7 text-gray-900'>{profile.fullName}</h3>
        </div>

        <div className='border-t border-gray-100'>
          <dl className='divide-y divide-gray-100'>
            <Item
              label='Email'
              value={profile.email}
            />
            <Item
              label='Phone number'
              value={profile.phoneNumber ? `${dialCode} ${profile.phoneNumber}` : 'N/A'}
            />
            <Item
              label='Organization role'
              value={profile.organizationRole ? startCase(profile.organizationRole) : 'N/A'}
            />
            <Item
              label='Trial started at'
              value={profile.trialStartedAt ? formatTimestamp(profile.trialStartedAt) : 'N/A'}
            />
            <Item
              label='Trial length'
              value={profile.trialLength ? `${profile.trialLength} days` : 'N/A'}
            />
            <Item
              label='Completed onboarding'
              value={profile.completedOnboarding ? 'Yes' : 'No'}
            />
          </dl>
        </div>
      </Card>

      <h3 className='text-lg font-semibold leading-7 text-gray-900 mb-3'>Classrooms</h3>

      <Choose>
        <When condition={isClassroomsLoading}>
          <Spinner />
        </When>

        <When condition={classrooms.length === 0}>
          <NoResults />
        </When>

        <Otherwise>
          <Table>
            <Table.Head>
              <Table.Row>
                <Table.Header>Name</Table.Header>
                <Table.Header>Archived</Table.Header>
                <Table.Header>Students</Table.Header>
                <Table.Header>Pending Invitations</Table.Header>
              </Table.Row>
            </Table.Head>

            <Table.Body>
              <For each='classroom' of={classrooms}>
                <Table.Row key={classroom.id}>
                  <Table.Cell>
                    {classroom.name}
                  </Table.Cell>
                  <Table.Cell>
                    {classroom.archived ? 'Yes' : 'No'}
                  </Table.Cell>
                  <Table.Cell>
                    {classroom.studentsCount}
                  </Table.Cell>
                  <Table.Cell>
                    {classroom.pendingInvitationsCount}
                  </Table.Cell>
                </Table.Row>
              </For>
            </Table.Body>
          </Table>

          <Pagination page={parseInt(page)} pagesCount={pagesCount} setPage={setPage} />
        </Otherwise>
      </Choose>
    </div>
  )
}

export default EducatorDetails
