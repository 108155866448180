import { useState } from 'react'
import { gql } from 'graphql-request'
import { useQuery, useMutation } from '@hooks/graphql'
import { useForm, Controller } from 'react-hook-form'

import Button from '@components/Button'
import DismissiblePill from '@components/DismissiblePill'
import Label from '@components/Label'
import Modal from '@components/Modal'
import Combobox from '@components/Combobox'

const EDUCATORS_QUERY = gql`
  query organization {
    organization {
      id
      educators {
        role
        profile {
          id
          fullName
          email
        }
      }
    }
  }
`

const ADD_COLLABORATORS_TO_CLASSROOM_MUTATION = gql`
  mutation addCollaboratorsToClassroom($input: AddCollaboratorsToClassroomInput!) {
    addCollaboratorsToClassroom(input: $input) {
      success
      errors {
        message
      }
    }
  }
`

const AddCollaboratorsModal = ({
  classroomId,
  closeModal,
  currentCollaborators,
  isOpen,
  classroomOwner: { id: ownerId },
  refetchCollaborators
}) => {
  const [searchTerm, setSearchTerm] = useState('')

  const { handleSubmit, reset, watch, control, setValue, formState: { errors } } = useForm({
    mode: 'onTouched',
    defaultValues: {
      collaborators: []
    }
  })

  const selectedCollaborators = watch('collaborators')

  const { data: { organization: { educators = [] } = {} } = {} } = useQuery({
    queryKey: ['educatorsList'],
    gqlQuery: EDUCATORS_QUERY,
  })

  const filteredEducators = educators.filter(({ profile }) => {
    const educatorName = profile.fullName.toLowerCase()

    const alreadyCollaborator = currentCollaborators.some(
      (collaborator) => collaborator.id === profile.id
    )
    return !alreadyCollaborator && educatorName.includes(searchTerm.toLowerCase())
  })

  const handleDismissEducator = (profileId) => {
    const newCollaborators = selectedCollaborators.filter(collaborator => collaborator.id !== profileId)
    setValue('collaborators', newCollaborators)
  }

  const handleCloseModal = () => {
    reset()
    closeModal()
  }

  const { mutate: addCollaboratorsToClassroom, isLoading: sending } = useMutation({
    mutationKey: ['addCollaboratorsToClassroom'],
    gqlMutation: ADD_COLLABORATORS_TO_CLASSROOM_MUTATION,
    onSuccess: () => {
      refetchCollaborators()
      reset()
      closeModal()
    }
  })

  const submit = (formData) => {
    const collaboratorIds = formData.collaborators.map(collaborator => collaborator.id)

    addCollaboratorsToClassroom({
      input: {
        classroomId,
        collaboratorIds
      }
    })
  }

  return (
    <Modal size='md' isOpen={isOpen} onClose={handleCloseModal}>
      <form
        onSubmit={handleSubmit(submit)}
        className='flex flex-col'
      >
        <h2 className='font-heading text-2xl font-bold leading-tight'>Add collaborators to your classroom</h2>

        <div className='w-full my-5'>
          <Label htmlFor='educators' title='Collaborators' required />

          <div className='flex flex-wrap'>
            <For each='collaborator' of={selectedCollaborators}>
              <DismissiblePill
                theme='light'
                key={collaborator.id}
                label={collaborator.fullName}
                onDismiss={() => handleDismissEducator(collaborator.id)}
                className='m-0 mr-1 mb-2'
              />
            </For>
          </div>

          <Controller
            id='collaborators'
            name='collaborators'
            control={control}
            rules={{ required: 'Select at least one collaborator to add to your classroom' }}
            render={({ field }) => (
              <Combobox
                {...field}
                placeholder='Type to search collaborators'
                multiple
                onSearch={text => setSearchTerm(text)}
                by={(a, b) => a.id === b.id}
              >
                <For each='educator' of={filteredEducators}>
                  <With profile={educator.profile}>
                    <If condition={profile.id != ownerId}>
                      <Combobox.Option key={profile.id} value={profile} label={profile.fullName} />
                    </If>
                  </With>
                </For>
              </Combobox>
            )}
          />

          <If condition={errors.collaborators}>
            <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.collaborators.message}</p>
          </If>
        </div>

        <If condition={errors.general}>
          <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>
            {errors.general.message}
          </p>
        </If>

        <Button
          disabled={errors.general || errors.collaborators || sending}
          className='w-fit ml-auto mt-5 disabled:opacity-50'
          type='submit'
          label={sending ? 'Adding...' : 'Add collaborators'}
        />
      </form>
    </Modal>
  )
}

export default AddCollaboratorsModal
