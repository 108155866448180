import { useState } from 'react'
import { gql } from 'graphql-request'

import { useCurrentUser } from '@contexts/currentUser'

import { useQuery, useMutation } from '@hooks/graphql'
import Card from '@components/Card'
import Spinner from '@components/Spinner'
import Table from '@components/Table'
import Pill from '@components/Pill'
import Toggle from '@components/Toggle'
import OverflowMenu from '@components/OverflowMenu'

// import EditStudentProfileModal from './EditStudentProfileModal'

const STUDENT_PROFILES_QUERY = gql`
  query classroom($id: ID!, $showArchived: Boolean) {
    classroom(id: $id) {
      students(showArchived: $showArchived) {
        role
        deactivated
        profile {
          id
          fullName
          firstName
          lastName
          email
        }
      }
    }
  }
`

const UPDATE_STUDENT_MEMBERSHIP_MUTATION = gql`
  mutation updateMembership($input: UpdateMembershipInput!) {
    updateMembership(input: $input) {
      membership {
        deactivated
      }
    }
  }
`

const REMOVE_STUDENT_FROM_CLASSROOM_MUTATION = gql`
  mutation removeStudentFromClassroom($input: RemoveStudentFromClassroomInput!) {
    removeStudentFromClassroom(input: $input) {
      success
      errors
    }
  }
`

const StudentProfilesList = ({ id }) => {
  // const [selectedStudent, setSelectedStudent] = useState(null)
  const [showArchived, setShowArchived] = useState(false)

  const { isLoading, refetch, data: { classroom: { students = [] } = {} } = {} } = useQuery({
    queryKey: ['studentProfiles', id, showArchived],
    gqlQuery: STUDENT_PROFILES_QUERY,
    variables: { id, showArchived }
  })

  const { mutate: updateStudentMembership } = useMutation({
    gqlMutation: UPDATE_STUDENT_MEMBERSHIP_MUTATION,
    onSuccess: () => refetch()
  })

  const { mutate: removeStudentFromClassroom } = useMutation({
    gqlMutation: REMOVE_STUDENT_FROM_CLASSROOM_MUTATION,
    onSuccess: () => refetch()
  })

  const { currentMembership: { role } } = useCurrentUser()

  return (
    <div className='w-full'>
      <Card className='p-5 sm:mx-5 my-5 w-auto'>
        <Toggle
          value={showArchived}
          onChange={() => setShowArchived(!showArchived)}
          label='Show archived students'
        />
      </Card>

      <Choose>
        <When condition={isLoading}>
          <Spinner className='relative top-0 left-0 flex items-center justify-center w-full h-screen' />
        </When>

        <Otherwise>
          <div className='mt-3 sm:mx-5 p-0.5 md:mt-0'>
            <Table>
              <Table.Head>
                <Table.Row>
                  <Table.Header width='25%'>Name</Table.Header>
                  <Table.Header width='35%'>Email</Table.Header>
                  <Table.Header width='15%'>Role</Table.Header>
                  <Table.Header width='15%' />
                </Table.Row>
              </Table.Head>

              <Table.Body>
                <If condition={students.length < 1}>
                  <Table.Row>
                    <Table.Cell colSpan='4'>There aren't any students in this classroom yet, go ahead and invite them 😀</Table.Cell>
                  </Table.Row>
                </If>

                <For each='student' of={students}>
                  <With profile={student.profile}>
                    <Table.Row key={profile.id}>
                      <Table.Cell>
                        {profile.fullName}

                        <If condition={student.deactivated}>
                          <Pill className='ml-3 text-sm' theme='light' label='Archived' />
                        </If>
                      </Table.Cell>
                      <Table.Cell>{profile.email}</Table.Cell>
                      <Table.Cell>{student.role.toLowerCase()}</Table.Cell>
                      <Table.Cell className='flex items-center justify-end'>
                        <OverflowMenu orientation='horizontal'>
                          <OverflowMenu.Item>
                            <button onClick={() => removeStudentFromClassroom({ input: { classroomId: id, profileId: profile.id } })} className='w-full text-left'>
                              Remove from classroom
                            </button>
                          </OverflowMenu.Item>
                          <If condition={role == 'admin'}>
                            <Choose>
                              <When condition={student.deactivated}>
                                <OverflowMenu.Item>
                                  <button onClick={() => updateStudentMembership({ input: { profileId: profile.id, deactivated: false } })} className='w-full text-left'>
                                    Unarchive account
                                  </button>
                                </OverflowMenu.Item>
                              </When>
                              <Otherwise>
                                <OverflowMenu.Item>
                                  <button onClick={() => updateStudentMembership({ input: { profileId: profile.id, deactivated: true } })} className='w-full text-left text-red-500'>
                                    Archive account
                                  </button>
                                </OverflowMenu.Item>
                              </Otherwise>
                            </Choose>
                          </If>
                        </OverflowMenu>

                      </Table.Cell>
                    </Table.Row>
                  </With>
                </For>
              </Table.Body>
            </Table>
          </div>

          {/* <If condition={!!selectedStudent}>
            <EditStudentProfileModal
              id={selectedStudent.id}
              firstName={selectedStudent.firstName}
              lastName={selectedStudent.lastName}
              email={selectedStudent.email}
              onClose={() => setSelectedStudent(null)}
              isOpen={!!selectedStudent}
              refetch={refetch}
            />
          </If> */}

        </Otherwise>
      </Choose>
    </div>
  )
}

export default StudentProfilesList
