import { Routes, Route, Navigate, useLocation } from 'react-router-dom'

import { useCurrentUser } from '@contexts/currentUser'

import Projects from './pages/Projects'
import SeriesDetails from './pages/SeriesDetails'
import SeriesPreview from './pages/SeriesPreview'
import Community from './pages/Community'
import EducatorProjectDetails from './pages/EducatorProjectDetails'
import ClassroomDetails from './pages/ClassroomDetails'
import ClassroomList from './pages/ClassroomList'
import CreatorList from './pages/CreatorList'
import CreatorDetails from './pages/CreatorDetails'
import Workshops from './pages/Workshops'
import CourseActivity from './pages/CourseActivity'
import Settings from './pages/Settings'
import Organization from './pages/Organization'
import TutorList from './pages/TutorList'
import TutorDetails from './pages/TutorDetails'
import SessionDetails from './pages/SessionDetails'
import NewTutor from './pages/NewTutor'
import Upgrade from './pages/Upgrade'
import Onboarding from './pages/Onboarding'
import Archived from './pages/Archived'

const Educator = () => {
  const location = useLocation()
  const { user, currentMembership } = useCurrentUser()

  if (currentMembership?.role !== 'educator' && currentMembership?.role !== 'admin') {
    return <Navigate to='/' />
  }

  // If onboarding is not completed and user is not currently onboarding
  if (!user?.onboarding_completed && location.pathname !== '/educators/onboarding') {
    return <Navigate to='/educators/onboarding' />
  }

  return (
    <>
      <Routes>
        <Route element={<Archived />}>
          <Route index element={<Navigate to='/educators/tutors?audience=community' />} />
          <Route path='/projects' element={<Navigate to='/educators/courses' />} />
          <Route path='/courses' element={<Projects />} />
          <Route path='/courses/:id/*' element={<SeriesDetails />} />
          <Route path='/projects/:id/*' element={<EducatorProjectDetails />} />
          <Route path='/tutors' element={<TutorList />} />
          <Route path='/tutors/new' element={<NewTutor />} />
          <Route path='/tutors/:id/*' element={<TutorDetails />} />
          <Route path='/creators' element={<CreatorList />} />
          <Route path='/creators/:id/*' element={<CreatorDetails />} />
          <Route path='/classrooms/:classroomId/courses/:courseId/*' element={<CourseActivity />} />
          <Route path='/classrooms/:classroomId/tutors/:tutorId/sessions/:id/*' element={<SessionDetails />} />
          <Route path='/classrooms/:id/*' element={<ClassroomDetails />} />
          <Route path='/classrooms' element={<ClassroomList />} />
          <Route path='/organization/:tab' element={<Organization />} />
          <Route path='/organization' element={<Organization />} />
          <Route path='/settings' element={<Settings />} />
          <Route path='/workshops' element={<Workshops />} />
          <Route path='/upgrade' element={<Upgrade />} />
          <Route path='/onboarding' element={<Onboarding />} />
        </Route>
      </Routes>
    </>
  )
}

export default Educator
