import React from 'react'
import ReactDOM from 'react-dom/client'
import * as Sentry from "@sentry/react"

import App from './App'

import './index.css'

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration()],
  environment: import.meta.env.VITE_ENV,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.1,
})

ReactDOM.createRoot(document.getElementById('root')).render(
  <App />
)
