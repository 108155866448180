import { Link, useSearchParams } from 'react-router-dom'
import { gql } from 'graphql-request'
import { LockClosedIcon } from '@heroicons/react/24/outline'
import { BoltIcon } from '@heroicons/react/24/solid'

import { durationInWords } from '@helpers/format'
import { useQuery } from '@hooks/graphql'
import Card from '@components/Card'
import Pagination from '@components/Pagination'
import Spinner from '@components/Spinner'
import CourseCard from '@components/CourseCard'
import Listbox from '@components/Listbox'
import Label from '@components/Label'

import NoResults from './NoResults'

const CLASSROOM_ASSIGNMENTS_QUERY = gql`
  query classroomAssignments($id: ID!, $page: Int, $closed: Boolean) {
    classroom(id: $id) {
      seriesAssignments(page: $page, perPage: 8, closed: $closed) {
        nodes {
          id
          createdAt
          closed
          series {
            id
            name
            coverImage {
              url
            }
          }
          profile {
            fullName
          }
        }
        pagesCount
        nodesCount
      }
    }
  }
`

const CoursesList = ({ id }) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const page = searchParams.get('page') || 1
  const status = searchParams.get('status') || null

  const { isLoading, data: { classroom: { seriesAssignments: { nodes: assignments = [], pagesCount, nodesCount } = {} } = {} } = {} } = useQuery({
    queryKey: ['classroomAssignments', id, page, status],
    gqlQuery: CLASSROOM_ASSIGNMENTS_QUERY,
    variables: { id, page: parseInt(page), closed: status ? status === 'closed' : null }
  })

  const setPage = page => {
    if (status === null) {
      setSearchParams({ page })
    } else {
      setSearchParams({ page, status })
    }
  }

  return (
    <div className='sm:px-5 pt-5'>

      <Card className='mb-5 p-3 overflow-visible'>
        <Label htmlFor='status'>Status</Label>
        <Listbox
          id='status'
          className='w-[300px]'
          theme='secondary'
          placeholder='All courses'
          label={
            <div className='flex items-center'>
              <Choose>
                <When condition={status === 'closed'}>
                  <LockClosedIcon className='size-5 mr-3 shrink-0 text-red-500' />
                  Closed
                </When>

                <When condition={status === 'open'}>
                  <BoltIcon className='size-4 mr-3 shrink-0 text-green-500' />
                  Open
                </When>
              </Choose>
            </div>
          }
          value={status}
          onChange={value => {
            if (value === null) {
              setSearchParams({})
            } else {
              setSearchParams({ status: value })
            }
          }}
        >
          <Listbox.Option
            value={null}
            label={<p className='flex items-center leading-none ml-8 h-5'>All courses</p>}
          />
          <Listbox.Option
            value='open'
            label={
              <div className='flex mt-1'>
                <BoltIcon className='size-5 mr-3 shrink-0 text-green-500' />
                <p className='leading-none'>Open courses</p>
              </div>
            }
          />

          <Listbox.Option
            value='closed'
            label={
              <div className='flex mt-1'>
                <LockClosedIcon className='size-5 mr-3 shrink-0 text-red-500' />
                <p className='leading-none'>Closed courses</p>
              </div>
            }
          />
        </Listbox>
      </Card>

      <Choose>
        <When condition={isLoading}>
          <Spinner className='flex items-center justify-center w-full h-full' />
        </When>

        <When condition={nodesCount === 0}>
          <NoResults />
        </When>

        <Otherwise>

          <div className='grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-5'>
            <For each='assignment' of={assignments}>
              <With series={assignment.series}>
                <Link
                  key={assignment.id}
                  to={`/educators/classrooms/${id}/courses/${series.id}`}
                >
                  <CourseCard
                    name={series.name}
                    description={series.description}
                    imageUrl={series.coverImage?.url}
                  />

                  <div className='flex items-center mt-1 sm:ml-0 ml-3'>
                    <Choose>
                      <When condition={assignment.closed}>
                        <LockClosedIcon className='size-4 mr-2 shrink-0 text-red-500' />
                        <p className='pt-1 text-sm leading-none text-gray-500'>Closed, assigned by {assignment.profile.fullName}</p>
                      </When>

                      <Otherwise>
                        <BoltIcon className='size-4 mr-2 shrink-0 text-green-500' />
                        <p className='pt-1 text-sm leading-none text-gray-500'>Assigned {durationInWords(assignment.createdAt)}, by {assignment.profile.fullName}</p>
                      </Otherwise>
                    </Choose>
                  </div>
                </Link>
              </With>
            </For>
          </div>

          <Pagination page={parseInt(page)} pagesCount={pagesCount} setPage={setPage} />
        </Otherwise>
      </Choose>
    </div>
  )
}

export default CoursesList
