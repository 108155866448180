import { useState } from 'react'

import Table from '@components/Table'
import Button from '@components/Button'
import CollaboratorsListActions from './CollaboratorsListActions'
import AddCollaboratorsModal from './AddCollaboratorsModal'

import { useCurrentUser } from '@contexts/currentUser'

const Collaborators = ({ classroomId, collaborators, classroomOwner, refetch }) => {
  const { user: { profile_id: profileId } = {} } = useCurrentUser()

  const [showAddCollaboratorsModal, setShowAddCollaboratorsModal] = useState(false)

  const educators = [
    { ...classroomOwner, role: 'owner' },
    ...collaborators.map(collaborator => ({ ...collaborator, role: 'collaborator' }))
  ]

  return (
    <div className='mt-5'>
      <div className='mt-3 sm:mx-5 p-0.5 md:mt-0'>

        <div className='flex justify-end mb-3 mr-5 md:mr-0'>
          <If condition={profileId === classroomOwner.id}>
            <Button
              label='Add collaborators'
              className='ml-auto mb-3 mr-5 md:mr-0'
              variant='outlined'
              theme='light'
              onClick={() => setShowAddCollaboratorsModal(true)}
            />
          </If>
        </div>

        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Header>Name</Table.Header>
              <Table.Header>Email</Table.Header>
              <Table.Header />
              <Table.Header />
            </Table.Row>
          </Table.Head>

          <Table.Body>
            <If condition={educators.length < 1}>
              <Table.Row>
                <Table.Cell colSpan='4'>There aren't any collaborators in this classroom yet, go ahead and invite them 😀</Table.Cell>
              </Table.Row>
            </If>

            <For each='educator' of={educators}>
              <Table.Row key={educator.id}>
                <Table.Cell>
                  {educator.fullName}
                </Table.Cell>

                <Table.Cell>
                  {educator.email}
                </Table.Cell>

                <Table.Cell>
                  {educator.role}
                </Table.Cell>

                <Table.Cell className='flex items-center justify-end'>
                  <CollaboratorsListActions
                    id={educator.id}
                    classroomId={classroomId}
                    isOwner={educator.role === 'owner'}
                    currentUserIsOwner={profileId === classroomOwner.id}
                    currentUserIsCollaborator={profileId === educator.id}
                    refetchCollaborators={refetch}
                  />
                </Table.Cell>
              </Table.Row>
            </For>
          </Table.Body>
        </Table>
      </div>

      <AddCollaboratorsModal
        classroomId={classroomId}
        classroomOwner={classroomOwner}
        isOpen={showAddCollaboratorsModal}
        closeModal={() => setShowAddCollaboratorsModal(false)}
        refetchCollaborators={refetch}
        currentCollaborators={collaborators}
      />
    </div>
  )
}

export default Collaborators
