import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { gql } from 'graphql-request'

import { useQuery, useMutation } from '@hooks/graphql'
import Table from '@components/Table'
import Spinner from '@components/Spinner'
import SearchInput from '@components/SearchInput'
import Pill from '@components/Pill'
import OverflowMenu from '@components/OverflowMenu'
import Card from '@components/Card'
import Toggle from '@components/Toggle'
import Pagination from '@components/Pagination'

import NoResults from './NoResults'
import EmptyList from './EmptyList'

const STUDENTS_QUERY = gql`
  query students($search: String, $showArchived: Boolean, $page: Int!) {
    organization {
      id
      name
      students(search: $search, showArchived: $showArchived, page: $page, perPage: 10) {
        nodes {
          role
          deactivated
          profile {
            id
            fullName
            email
          }
        }
        pagesCount
      }
    }
  }
`

const UPDATE_STUDENT_MEMBERSHIP_MUTATION = gql`
  mutation updateMembership($input: UpdateMembershipInput!) {
    updateMembership(input: $input) {
      membership {
        deactivated
      }
    }
  }
`

const StudentList = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const pageParam = Number(searchParams.get('page')) || 1
  const searchParam = searchParams.get('search') || ''

  const [localSearchTerm, setLocalSearchTerm] = useState(searchParam)
  const [showArchived, setShowArchived] = useState(false)

  const { isLoading, refetch, data } = useQuery({
    queryKey: ['students', searchParam, showArchived, pageParam],
    gqlQuery: STUDENTS_QUERY,
    variables: { search: searchParam, showArchived, page: pageParam }
  })

  const organization = data?.organization
  const { nodes: students = [], pagesCount } = organization?.students || {}

  const { mutate: updateStudentMembership } = useMutation({
    gqlMutation: UPDATE_STUDENT_MEMBERSHIP_MUTATION,
    onSuccess: () => refetch()
  })

  const handleSearch = () => {
    const nextSearchParams = {}
    if (localSearchTerm) nextSearchParams.search = localSearchTerm
    if (pageParam !== 1) nextSearchParams.page = '1' // reset page on new search
    setSearchParams(nextSearchParams)
  }

  const clearSearch = () => {
    setLocalSearchTerm('')
    setSearchParams({})
  }

  const handlePageChange = newPage => {
    const nextSearchParams = {}
    if (searchParam) nextSearchParams.search = searchParam
    if (newPage !== 1) nextSearchParams.page = newPage
    setSearchParams(nextSearchParams)
  }

  if (isLoading) {
    return (
      <Spinner className='relative top-0 left-0 flex items-center justify-center w-full h-screen' />
    )
  }

  const showEmptyList = !searchParam && students.length === 0
  const showNoResults = searchParam && students.length === 0

  return (
    <>
      <Card className='p-5 my-5 w-auto'>
        <div className='flex gap-8 flex-col md:flex-row'>
          <SearchInput
            value={localSearchTerm}
            onChange={e => setLocalSearchTerm(e.target.value)}
            onSearch={handleSearch}
            onClear={clearSearch}
          />

          <Toggle
            value={showArchived}
            onChange={() => setShowArchived(!showArchived)}
            label='Show&nbsp;archived&nbsp;students'
          />
        </div>
      </Card>

      <Choose>
        <When condition={showEmptyList}>
          <EmptyList />
        </When>

        <When condition={showNoResults}>
          <NoResults clearSearch={clearSearch} />
        </When>

        <Otherwise>
          <div className='flex flex-col w-full pb-10'>
            <Table>
              <Table.Head>
                <Table.Row>
                  <Table.Header width='25%'>Name</Table.Header>
                  <Table.Header width='35%'>Email</Table.Header>
                  <Table.Header width='15%'>Role</Table.Header>
                  <Table.Header width='15%' />
                </Table.Row>
              </Table.Head>

              <Table.Body>
                <For each='student' of={students}>
                  <With profile={student.profile}>
                    <Table.Row key={profile.id}>
                      <Table.Cell>
                        {profile.fullName}
                        <If condition={student.deactivated}>
                          <Pill className='ml-3 text-sm' theme='light' label='Archived' />
                        </If>
                      </Table.Cell>
                      <Table.Cell>{profile.email}</Table.Cell>
                      <Table.Cell>{student.role.toLowerCase()}</Table.Cell>
                      <Table.Cell className='flex items-center justify-end'>
                        <OverflowMenu orientation='horizontal'>
                          <Choose>
                            <When condition={student.deactivated}>
                              <OverflowMenu.Item>
                                <button
                                  onClick={() => updateStudentMembership({ input: { profileId: profile.id, deactivated: false } })}
                                  className='w-full text-left'
                                >
                                  Unarchive account
                                </button>
                              </OverflowMenu.Item>
                            </When>
                            <Otherwise>
                              <OverflowMenu.Item>
                                <button
                                  onClick={() => updateStudentMembership({ input: { profileId: profile.id, deactivated: true } })}
                                  className='w-full text-left text-red-500'
                                >
                                  Archive account
                                </button>
                              </OverflowMenu.Item>
                            </Otherwise>
                          </Choose>
                        </OverflowMenu>
                      </Table.Cell>
                    </Table.Row>
                  </With>
                </For>
              </Table.Body>
            </Table>

            {pagesCount > 1 && (
              <Pagination
                page={pageParam}
                pagesCount={pagesCount}
                setPage={handlePageChange}
              />
            )}
          </div>
        </Otherwise>
      </Choose>
    </>
  )
}

export default StudentList
