import { gql } from 'graphql-request'
import { useSearchParams } from 'react-router-dom'
import { ResponsiveContainer, BarChart, ScatterChart, CartesianGrid, XAxis, YAxis, Tooltip, Bar, Scatter } from 'recharts'

import { capitalize, orderBy, round } from 'lodash'
import { CloudArrowDownIcon, EyeIcon, EyeSlashIcon, ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/24/outline'

import { useQuery } from '@hooks/graphql'
import { formatDuration } from '@helpers/format'
import Select from '@components/Select'
import Table from '@components/Table'
import Button from '@components/Button'
import Card from '@components/Card'
import Spinner from '@components/Spinner'
import NoResults from './NoResults'

import { useState } from 'react'

const CLASSROOM_INSIGHTS_QUERY = gql`
  query classroomInsights($classroomId: ID!, $insightableId: ID!) {
    classroomInsights(classroomId: $classroomId, insightableId: $insightableId) {
      profile {
        firstName
        lastName
      }
      totalTimeSeconds
      activeTimeSeconds
      questionsAsked
      questionsAnswered
      answerQuality
      group
      misconceptionsConcepts
      misconceptionsDetails           # Add this
      misconceptionsRootCauses       # Add this
      misconceptionsSyllabusConnection # Add this
      misconceptionsWorthReteaching   # Add this
    }
  }
`

const Insights = ({ id, classroomId, educatorProjects }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const selectedLessonId = searchParams.get('lessonId') || educatorProjects?.[0]?.id || null

  const setSelectedLessonId = lessonId => {
    setSearchParams({ lessonId })
  }

  const [showAllMisconceptions, setShowAllMisconceptions] = useState(false)
  const [expandedItems, setExpandedItems] = useState(new Set())
  const [showAllRootCauses, setShowAllRootCauses] = useState(false)
  const [showAllTopics, setShowAllTopics] = useState(false)

  const { data: { classroomInsights: rawData = [] } = {}, isInitialLoading: isLoading } = useQuery({
    queryKey: ['classroomInsights', classroomId, selectedLessonId],
    gqlQuery: CLASSROOM_INSIGHTS_QUERY,
    variables: { classroomId, insightableId: selectedLessonId || id },
    enabled: !!selectedLessonId
  })

  const flattenData = (data) => {
    // First create the flattened data
    const flattenedData = data.map(item => {
      // Base student data
      const baseData = {
        'Student Name': `${item.profile?.lastName || ''}, ${item.profile?.firstName || ''}`,
        'Total Time (mins)': round(item.totalTimeSeconds / 60, 2) || 0,
        'Active Time (mins)': round(item.activeTimeSeconds / 60, 2) || 0,
        'Questions Asked': item.questionsAsked || 0,
        'Questions Answered': item.questionsAnswered || 0,
        AnswerQuality: capitalize(item.answerQuality || ''),
        Group: item.group || ''
      }

      // Add misconception data
      if (item.misconceptionsConcepts?.length) {
        item.misconceptionsConcepts.forEach((concept, index) => {
          baseData[`Misconception ${index + 1}`] = concept || ''
          baseData[`Misconception ${index + 1} Detail`] = item.misconceptionsDetails?.[index] || ''
          baseData[`Misconception ${index + 1} Root Cause`] = item.misconceptionsRootCauses?.[index] || ''
        })
      }

      return baseData
    })

    // Sort the data by surname, then firstname
    return flattenedData.sort((a, b) => {
      const [aLastName = '', aFirstName = ''] = a['Student Name'].split(', ')
      const [bLastName = '', bFirstName = ''] = b['Student Name'].split(', ')

      // Compare surnames first
      const surnameComparison = aLastName.localeCompare(bLastName)

      // If surnames are equal, compare firstnames
      if (surnameComparison === 0) {
        return aFirstName.localeCompare(bFirstName)
      }

      return surnameComparison
    })
  }

  const scatterData = rawData.reduce((acc, student) => {
    const key = student.answerQuality
    const value = {
      y: student.questionsAsked,
      x: round(student.activeTimeSeconds / 60, 2),
      tooltipData: {
        Name: `${student.profile.firstName} ${student.profile.lastName}`,
        Questions: student.questionsAsked,
        Quality: capitalize(student.answerQuality),
        Time: formatDuration(student.activeTimeSeconds)
      }
    }

    if (!acc[key]) {
      acc[key] = []
    }

    acc[key].push(value)
    return acc
  }, {})

  const CustomTooltip = ({ active, payload, _label }) => {
    if (active && payload && payload.length) {
      return (
        <Card className='p-3'>
          {Object.entries(payload[0].payload.tooltipData || [])
            .filter(([_, value]) => value)
            .map(([key, value]) => (
              <div key={key} className='flex flex-col'>
                <span className='text-sm font-medium text-gray-500'>{key}</span>
                <span className='text-lg font-semibold text-gray-900'>{value}</span>
              </div>
            ))}

        </Card>
      )
    }

    return null
  }

  const handleBulkExpand = (allData) => {
    if (expandedItems.size === allData.length) {
      // If all items are expanded, collapse all
      setExpandedItems(new Set())
    } else {
      // Expand all items, including non-visible ones
      setExpandedItems(new Set(allData.map((_, index) => index)))
    }
  }

  // Modify the individual item expand handler
  const handleItemExpand = (index) => {
    const newExpandedItems = new Set(expandedItems)
    if (newExpandedItems.has(index)) {
      newExpandedItems.delete(index)
    } else {
      newExpandedItems.add(index)
    }
    setExpandedItems(newExpandedItems)
  }

  const cardData = [
    {
      title: 'Class Averages',
      type: 'cards',
      data: [
        { name: 'Total Time', value: formatDuration((rawData.reduce((acc, student) => acc + student.totalTimeSeconds, 0) / rawData.length)) },
        { name: 'Active Engagement', value: formatDuration((rawData.reduce((acc, student) => acc + student.activeTimeSeconds, 0) / rawData.length)) },
        { name: 'Questions Asked', value: (rawData.reduce((acc, student) => acc + student.questionsAsked, 0) / rawData.length).toFixed(0) },
        { name: 'Questions Answered', value: (rawData.reduce((acc, student) => acc + student.questionsAnswered, 0) / rawData.length).toFixed(0) }
      ]
    },
    {
      title: 'Misconceptions Analysis',
      type: 'expandableTable',
      data: orderBy(
        rawData.reduce((acc, student) => {
          if (!student.misconceptionsConcepts) return acc

          student.misconceptionsConcepts.forEach((misconception, index) => {
            const existingMisconception = acc.find(item => item.name === misconception)
            if (existingMisconception) {
              existingMisconception.frequency += 1
              if (student.misconceptionsDetails[index] &&
                !existingMisconception.details.includes(student.misconceptionsDetails[index])) {
                existingMisconception.details.push(student.misconceptionsDetails[index])
              }
            } else {
              acc.push({
                name: misconception,
                frequency: 1,
                details: student.misconceptionsDetails[index] ? [student.misconceptionsDetails[index]] : []
              })
            }
          })
          return acc
        }, []),
        ['frequency'],
        ['desc']
      )
    },
    {
      title: 'Root Causes',
      type: 'table',
      data: rawData.reduce((acc, student) => {
        if (!student.misconceptionsConcepts || !student.misconceptionsRootCauses) return acc

        student.misconceptionsConcepts.forEach((misconception, index) => {
          const rootCauses = student.misconceptionsRootCauses[index]?.split(',').map(cause => cause.trim()) || []

          const existingMisconception = acc.find(item => item.misconception === misconception)
          if (existingMisconception) {
            rootCauses.forEach(cause => {
              if (!existingMisconception.rootCauses.includes(cause)) {
                existingMisconception.rootCauses.push(cause)
              }
            })
          } else {
            acc.push({
              misconception,
              rootCauses: [...new Set(rootCauses)]
            })
          }
        })
        return acc
      }, [])
    },
    {
      title: 'Topics Worth Reteaching',
      type: 'table',
      data: orderBy(
        rawData.reduce((acc, student) => {
          if (!student.misconceptionsConcepts || !student.misconceptionsWorthReteaching) return acc

          student.misconceptionsConcepts.forEach((misconception, index) => {
            if (student.misconceptionsWorthReteaching[index] === 'Yes') {
              const existingTopic = acc.find(item => item.Topic === misconception)
              if (existingTopic) {
                existingTopic.frequency += 1
              } else {
                acc.push({
                  Topic: misconception,
                  frequency: 1
                })
              }
            }
          })
          return acc
        }, []),
        ['frequency'],
        ['desc']
      )
    },
    {
      title: 'Active Engagement Time',
      type: 'barChart',
      labels: {
        x: 'Student',
        y: 'Active Time (mins)'
      },
      angle: {
        x: -45
      },
      data: orderBy(
        rawData.map((student) => ({
          name: student.profile.firstName,
          value: round(student.activeTimeSeconds / 60, 2),
          tooltipData: { Name: `${student.profile.firstName} ${student.profile.lastName}`, Time: formatDuration(student.activeTimeSeconds) }
        })),
        ['name'],
        ['asc']
      )
    },
    {
      title: 'Student Engagement',
      type: 'scatterChart',
      units: {
        x: ' min'
      },
      data: scatterData
    }
  ]

  const renderCardContent = (card) => {
    switch (card.type) {
      case 'cards': {
        if (card.data.length === 4) {
          return (
            <dl className='mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4'>
              {card.data.map((item) => (
                <div key={item.name} className='overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6'>
                  <dt className='truncate text-sm font-medium text-gray-500'>{item.name}</dt>
                  <dd className='mt-1 text-3xl font-semibold tracking-tight text-gray-900'>{item.value}</dd>
                </div>
              ))}
            </dl>
          )
        }
        return (
          <dl className='mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3'>
            {card.data.map((item) => (
              <div key={item.name} className='overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6'>
                <dt className='truncate text-sm font-medium text-gray-500'>{item.name}</dt>
                <dd className='mt-1 text-3xl font-semibold tracking-tight text-gray-900'>{item.value}</dd>
              </div>
            ))}
          </dl>
        )
      }

      case 'expandableTable': {
        const displayData = showAllMisconceptions ? card.data : card.data.slice(0, 3)
        const areAllExpanded = expandedItems.size === card.data.length

        return (
          <div className='mt-5'>
            <Table>
              <Table.Head>
                <Table.Row>
                  <Table.Header>Topic</Table.Header>
                  <Table.Header>Frequency</Table.Header>
                  <Table.Header>
                    <button
                      onClick={() => handleBulkExpand(card.data)}
                      className='text-gray-500 w-8 h-8 flex items-center justify-center focus:outline-none'
                    >
                      {areAllExpanded
                        ? <EyeSlashIcon className='h-5 w-5' />
                        : <EyeIcon className='h-5 w-5' />}
                    </button>
                  </Table.Header>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                <If condition={card.data.length < 1}>
                  <Table.Row>
                    <Table.Cell colSpan='3'>No misconceptions found 😀</Table.Cell>
                  </Table.Row>
                </If>

                <For each='item' index='index' of={displayData}>
                  <>
                    <Table.Row className='bg-white hover:bg-gray-50'>
                      <Table.Cell className='font-medium text-gray-900'>
                        {item.name}
                      </Table.Cell>
                      <Table.Cell>{item.frequency}</Table.Cell>
                      <Table.Cell className='text-right'>
                        <button
                          onClick={() => handleItemExpand(index)}
                          className='text-gray-500 w-8 h-8 flex items-center justify-center focus:outline-none'
                        >
                          {expandedItems.has(index)
                            ? <ChevronUpIcon className='h-5 w-5' />
                            : <ChevronDownIcon className='h-5 w-5' />}
                        </button>
                      </Table.Cell>
                    </Table.Row>
                    <If condition={expandedItems.has(index) && item.details.length > 0}>
                      <Table.Row>
                        <Table.Cell colSpan='3' className='bg-white p-0'>
                          <div className='w-full divide-y divide-gray-200'>
                            <For each='detail' index='idx' of={item.details}>
                              <div key={idx} className='px-8 py-3 first:pt-3 last:pb-3'>
                                <p className='text-gray-600 whitespace-normal'>
                                  {detail.replace(/(T|t)he student/g, (match) =>
                                    match.charAt(0) === 'T' ? 'Student' : 'student'
                                  )}
                                </p>
                              </div>
                            </For>
                          </div>
                        </Table.Cell>
                      </Table.Row>
                    </If>
                  </>
                </For>
              </Table.Body>
            </Table>

            {card.data.length > 5 && (
              <div className='mt-4 text-center'>
                <button
                  onClick={() => setShowAllMisconceptions(!showAllMisconceptions)}
                  className='inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                >
                  {showAllMisconceptions ? 'Show Less' : `Show ${card.data.length - 3} More`}
                </button>
              </div>
            )}
          </div>
        )
      }

      case 'table': {
        if (card.title === 'Root Causes') {
          const displayData = showAllRootCauses
            ? card.data
            : card.data.slice(0, 3)

          return (
            <div className='mt-5'>
              <Table>
                <Table.Body>
                  <For each='group' of={displayData}>
                    <Table.Row className='bg-gray-50'>
                      <Table.Cell colSpan='2' className='font-medium'>
                        {group.misconception}
                      </Table.Cell>
                    </Table.Row>
                    <For each='cause' of={[...new Set(group.rootCauses)]}>
                      <Table.Row>
                        <Table.Cell className='pl-8'>{cause}</Table.Cell>
                      </Table.Row>
                    </For>
                  </For>
                </Table.Body>
              </Table>

              {card.data.length > 5 && (
                <div className='mt-4 text-center'>
                  <button
                    onClick={() => setShowAllRootCauses(!showAllRootCauses)}
                    className='inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                  >
                    {showAllRootCauses ? 'Show Less' : `Show ${card.data.length - 3} More`}
                  </button>
                </div>
              )}
            </div>
          )
        }
        if (card.title === 'Topics Worth Reteaching') {
          const displayData = showAllTopics
            ? card.data
            : card.data.slice(0, 3)

          return (
            <div className='mt-5'>
              <Table>
                <Table.Head>
                  <Table.Row>
                    <Table.Header>Topic</Table.Header>
                  </Table.Row>
                </Table.Head>
                <Table.Body>
                  <If condition={card.data.length < 1}>
                    <Table.Row>
                      <Table.Cell>No topics found 😀</Table.Cell>
                    </Table.Row>
                  </If>

                  <For each='row' of={displayData}>
                    <Table.Row key={row.Topic}>
                      <Table.Cell>{row.Topic}</Table.Cell>
                    </Table.Row>
                  </For>
                </Table.Body>
              </Table>

              {card.data.length > 3 && (
                <div className='mt-4 text-center'>
                  <button
                    onClick={() => setShowAllTopics(!showAllTopics)}
                    className='inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                  >
                    {showAllTopics ? 'Show Less' : `Show ${card.data.length - 3} More`}
                  </button>
                </div>
              )}
            </div>
          )
        }
        return (
          <div className='mt-5'>
            <Table>
              <Table.Head>
                <Table.Row>
                  <Table.Header>Topic</Table.Header>
                  <If condition={card.data[0]?.Frequency}>
                    <Table.Header>Frequency</Table.Header>
                  </If>
                </Table.Row>
              </Table.Head>

              <Table.Body>
                <If condition={card.data.length < 1}>
                  <Table.Row>
                    <Table.Cell colSpan='2'>No data found 😀</Table.Cell>
                  </Table.Row>
                </If>

                <For each='row' of={card.data}>
                  <Table.Row key={row.Topic}>
                    <Table.Cell>{row.Topic}</Table.Cell>
                    <If condition={row.Frequency}>
                      <Table.Cell>{row.Frequency}</Table.Cell>
                    </If>
                  </Table.Row>
                </For>
              </Table.Body>
            </Table>
          </div>
        )
      }

      case 'barChart':
        return (
          <Card className='items-center p-3'>
            <div className='mt-5 h-64'>
              <ResponsiveContainer width='100%' height='100%'>
                <BarChart data={card.data} margin={{ bottom: card.labels && card.labels.x && 45 }} style={{ cursor: 'pointer' }}>
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis interval={0} dataKey='name' angle={card.angle && card.angle.x} textAnchor={(card.angle && card.angle.x && 'end') || 'middle'} label={{ value: card.labels && card.labels.x, offset: -40, position: 'insideBottom' }} />
                  <YAxis label={{ value: card.labels && card.labels.y, angle: -90, offset: 20, position: 'insideBottomLeft' }} />
                  <Tooltip content={<CustomTooltip />} />
                  <Bar dataKey='value' fill='#3b82f6' />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Card>
        )

      case 'scatterChart':
        return (
          <Card className='items-center p-3'>
            <div className='mt-5 h-64'>
              <ResponsiveContainer width='100%' height='100%'>
                <ScatterChart
                  margin={{
                    top: 20,
                    right: 20,
                    bottom: 10,
                    left: 10
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis dataKey='x' type='number' unit={card.units && card.units.x} />
                  <YAxis dataKey='y' type='number' />
                  <Tooltip content={<CustomTooltip />} />
                  <Scatter data={card.data.null} fill='#64748b' />
                  <Scatter data={card.data.poor} fill='#ef4444' />
                  <Scatter data={card.data.satisfactory} fill='#eab308' />
                  <Scatter data={card.data.good} fill='#22c55e' />
                </ScatterChart>
              </ResponsiveContainer>
            </div>
          </Card>
        )

      default:
        return null
    }
  }

  return (
    <div className='sm:mx-5 my-3 space-y-4'>
      <Card className='flex items-center p-3'>
        <div className='flex space-x-4'>
          <Select
            label='Lesson'
            value={selectedLessonId}
            onChange={e => setSelectedLessonId(e.target.value)}
          >
            <Select.Option key='' value=''>Select a lesson</Select.Option>
            <For each='educatorProject' of={educatorProjects}>
              <Select.Option key={educatorProject.id} value={educatorProject.id}>{educatorProject.name}</Select.Option>
            </For>
          </Select>
        </div>

        <If condition={rawData.length > 0}>
          <div className='ml-auto'>
            <Button
              onClick={() => {
                try {
                  const data = flattenData(rawData)
                  // Create CSV content manually with forced comma delimiters
                  const headers = Object.keys(data[0])
                  const csvContent = [
                    headers.join(','),
                    ...data.map(row =>
                      headers.map(header =>
                        // Wrap values in quotes and escape any existing quotes
                        `"${String(row[header] || '').replace(/"/g, '""')}"`
                      ).join(',')
                    )
                  ].join('\n')

                  // Create and trigger download
                  const blob = new Blob([csvContent], { type: 'text/csvcharset=utf-8' })
                  const link = document.createElement('a')
                  link.href = URL.createObjectURL(blob)
                  link.download = 'mindjoy-export.csv'
                  link.click()
                  URL.revokeObjectURL(link.href)
                } catch (error) {
                  console.error('CSV Export error:', error)
                }
              }}
              theme='secondary'
              variant='outlined'
              className='flex items-center'
              label={<span className='flex gap-2 items-center'>CSV<CloudArrowDownIcon className='h-6 w-6' /></span>}
            />
          </div>
        </If>
      </Card>

      <Choose>
        <When condition={isLoading}>
          <Spinner className='flex items-center justify-center w-full mt-24' />
        </When>

        <When condition={rawData.length < 1}>
          <NoResults lessonSelected={!!selectedLessonId} />
        </When>

        <Otherwise>
          <div className='mx-5 sm:mx-0 my-4 space-y-8 pb-5'>
            <If condition={rawData.length > 0}>
              {cardData.map((card, index) => (
                <div key={index} class='space-y-1'>
                  <h3 className='mx-3 sm-mx-0 text-lg font-semibold text-gray-900'>{card.title}</h3>
                  {renderCardContent(card)}
                </div>
              ))}
            </If>
          </div>
        </Otherwise>
      </Choose>
    </div>
  )
}

export default Insights
