import { useState } from 'react'
import { gql } from 'graphql-request'

import { useQuery } from '@hooks/graphql'
import Button from '@components/Button'
import Table from '@components/Table'
import Spinner from '@components/Spinner'
import Pill from '@components/Pill'
import Toggle from '@components/Toggle'
import Card from '@components/Card'

import { useCurrentUser } from '@contexts/currentUser'
import InviteEducatorsModal from './InviteEducatorsModal'
import EducatorListActions from './EducatorListActions'

const EDUCATORS_QUERY = gql`
  query organization($showArchived: Boolean) {
    organization {
      id
      educators(showArchived: $showArchived) {
        role
        deactivated
        profile {
          id
          fullName
          email
        }
      }
    }
  }
`

const EducatorList = () => {
  const { user: { email: currentUserEmail } = {}, currentMembership: { role: currentUserAuthRole } } = useCurrentUser()

  const [showArchived, setShowArchived] = useState(false)
  const [showInviteModal, setShowInviteModal] = useState(false)

  const { isLoading, data: { organization = {} } = {}, refetch } = useQuery({
    queryKey: ['educators', showArchived],
    gqlQuery: EDUCATORS_QUERY,
    variables: { showArchived }
  })

  if (isLoading) {
    return <Spinner className='relative top-0 left-0 flex items-center justify-center w-full h-screen' />
  }

  return (
    <>
      <div className='flex flex-col w-full pb-10'>

        <Card className='p-5 my-5 w-auto flex place-content-between'>
          <Toggle
            label='Show archived educators'
            value={showArchived}
            onChange={() => setShowArchived((prev) => !prev)}
            className='mr-5'
          />

          <Button
            label='Invite educators'
            onClick={() => setShowInviteModal(true)}
          />
        </Card>

        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Header>Name</Table.Header>
              <Table.Header>Email</Table.Header>
              <Table.Header>Role</Table.Header>
              <Table.Header />
            </Table.Row>
          </Table.Head>

          <Table.Body>
            <For each='educator' of={organization.educators}>
              <With profile={educator.profile}>
                <Table.Row key={profile.id}>
                  <Table.Cell>
                    {profile.fullName}
                    <If condition={educator.deactivated}>
                      <Pill className='ml-3 text-sm' theme='light' label='Archived' />
                    </If>
                  </Table.Cell>
                  <Table.Cell>{profile.email}</Table.Cell>
                  <Table.Cell>{educator.role.toLowerCase()}</Table.Cell>
                  <Table.Cell className='flex items-center justify-end'>
                    <If condition={currentUserAuthRole === 'admin' && currentUserEmail !== profile.email}>
                      <EducatorListActions
                        id={profile.id}
                        email={profile.email}
                        fullName={profile.fullName}
                        role={educator.role}
                        deactivated={educator.deactivated}
                        refetch={refetch}
                      />
                    </If>
                  </Table.Cell>
                </Table.Row>
              </With>
            </For>
          </Table.Body>
        </Table>
      </div>

      <InviteEducatorsModal
        organizationId={organization.id}
        isOpen={showInviteModal}
        closeModal={() => setShowInviteModal(false)}
      />
    </>
  )
}

export default EducatorList
