import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { gql } from 'graphql-request'

import { useQuery } from '@hooks/graphql'
import { Tab } from '@headlessui/react'
import { UserIcon, UsersIcon, BuildingOfficeIcon } from '@heroicons/react/24/outline'
import PageHeading from '@components/PageHeading'
import Spinner from '@components/Spinner'

import { useCurrentUser } from '@contexts/currentUser'

import EducatorList from './EducatorList'
import StudentList from './StudentList'
import Profile from './Profile'

const ORGANIZATION_QUERY = gql`
  query organization {
    organization {
      id
      name
      organizationType
    }
  }
`

const Organization = () => {
  const { currentMembership: { role } } = useCurrentUser()
  const isEducatorAdmin = role === 'admin'

  const tabList = [...isEducatorAdmin ? ['profile'] : [], 'educators', ...isEducatorAdmin ? ['students'] : []]

  const selectTab = tabTitle => tabList.indexOf(tabTitle)

  const { tab } = useParams()
  const navigate = useNavigate()
  const [selectedTab, setSelectedTab] = useState(selectTab(tab))

  useEffect(() => {
    setSelectedTab(selectTab(tab))
  }, [tab])

  const handleTabChange = tabIndex => {
    setSelectedTab(tabIndex)
    navigate(`/educators/organization/${tabList[tabIndex]}`)
  }

  const { isLoading, refetch, data: { organization = {} } = {} } = useQuery({
    queryKey: ['organization'],
    gqlQuery: ORGANIZATION_QUERY,
    enabled: isEducatorAdmin
  })

  if (isEducatorAdmin && isLoading) {
    return <Spinner className='relative top-0 left-0 flex items-center justify-center w-full h-screen' />
  }

  return (
    <>
      <PageHeading title='Organization' />

      <Tab.Group as='div' className='flex flex-col md:flex-row md:h-full' selectedIndex={selectedTab} onChange={handleTabChange}>
        <Tab.List className='flex flex-col md:basis-[200px] basis-0 grow-0 shrink-0 text-base font-semibold p-5 h-full border-gray-200 border-r'>
          <If condition={isEducatorAdmin}>
            <Tab className='ui-selected:bg-gray-200 ui-selected:font-semibold hover:text-blue-600 hover:bg-gray-200 group flex gap-x-3 rounded-md p-2 leading-6 font-semibold'>
              <BuildingOfficeIcon className='h-5 w-5 inline-block mr-2' />
              Profile
            </Tab>
          </If>

          <Tab className='ui-selected:bg-gray-200 ui-selected:font-semibold hover:text-blue-600 hover:bg-gray-200 group flex gap-x-3 rounded-md p-2 leading-6 font-semibold'>
            <UserIcon className='h-5 w-5 inline-block mr-2' />
            Educators
          </Tab>

          <If condition={isEducatorAdmin}>
            <Tab className='ui-selected:bg-gray-200 ui-selected:font-semibold hover:text-blue-600 hover:bg-gray-200 group flex gap-x-3 rounded-md p-2 leading-6 font-semibold'>
              <UsersIcon className='h-5 w-5 inline-block mr-2' />
              Students
            </Tab>
          </If>
        </Tab.List>

        <Tab.Panels className='flex-grow md:mx-5 my-3'>
          <If condition={isEducatorAdmin}>
            <Tab.Panel>
              <Profile
                organizationId={organization.id}
                name={organization.name}
                organizationType={organization.organizationType}
                refetch={refetch}
              />
            </Tab.Panel>
          </If>

          <Tab.Panel>
            <EducatorList />
          </Tab.Panel>

          <If condition={isEducatorAdmin}>
            <Tab.Panel>
              <StudentList />
            </Tab.Panel>
          </If>
        </Tab.Panels>
      </Tab.Group>
    </>
  )
}

export default Organization
