import { gql } from 'graphql-request'

import { useMutation } from '@hooks/graphql'
import OverflowMenu from '@components/OverflowMenu'

const UPDATE_AUTHORIZATION_ROLE_MUTATION = gql`
  mutation updateMembershipRole($input: UpdateRoleInput!) {
    updateMembershipRole(input: $input) {
      membership {
        role
      }
      errors {
        message
      }
    }
  }
`

const UPDATE_MEMBERSHIP_MUTATION = gql`
  mutation updateMembership($input: UpdateMembershipInput!) {
    updateMembership(input: $input) {
      membership {
        deactivated
      }
      errors {
        message
      }
    }
  }
`

const EducatorListActions = ({ id, email, fullName, role, deactivated, refetch }) => {
  const educatorNameOrEmail = fullName.trim() === '' ? email : fullName

  const { mutate: updateEducatorRole } = useMutation({
    gqlMutation: UPDATE_AUTHORIZATION_ROLE_MUTATION,
    onSuccess: refetch
  })

  const handleRoleChange = (id, newRole) => {
    updateEducatorRole({
      input: {
        profileId: id,
        role: newRole
      }
    })
  }

  const { mutate: updateMembership } = useMutation({
    gqlMutation: UPDATE_MEMBERSHIP_MUTATION,
    onSuccess: refetch
  })

  const handleArchiveToggle = (profileId, deactivated) => {
    updateMembership({
      input: {
        profileId,
        deactivated
      }
    })
  }

  return (
    <OverflowMenu orientation='horizontal'>
      <Choose>
        <When condition={role === 'ADMIN'}>
          <OverflowMenu.Item>
            <button
              onClick={() => handleRoleChange(id, 'EDUCATOR')}
              className='text-md w-full text-left'
            >
              Make {educatorNameOrEmail} an educator
            </button>
          </OverflowMenu.Item>
        </When>

        <Otherwise>
          <OverflowMenu.Item>
            <button
              onClick={() => handleRoleChange(id, 'ADMIN')}
              className='text-md w-full text-left'
            >
              Make {educatorNameOrEmail} an admin
            </button>
          </OverflowMenu.Item>
        </Otherwise>
      </Choose>
      <Choose>
        <When condition={deactivated}>
          <OverflowMenu.Item>
            <button
              onClick={() => handleArchiveToggle(id, false)}
              className='text-md w-full text-left'
            >
              Unarchive account
            </button>
          </OverflowMenu.Item>
        </When>
        <Otherwise>
          <OverflowMenu.Item>
            <button
              onClick={() => handleArchiveToggle(id, true)}
              className='text-md w-full text-left text-red-500'
            >
              Archive account
            </button>
          </OverflowMenu.Item>
        </Otherwise>
      </Choose>
    </OverflowMenu>
  )
}

export default EducatorListActions
