import { LightBulbIcon, UserPlusIcon, ChatBubbleLeftEllipsisIcon, BookOpenIcon, CalendarDaysIcon } from '@heroicons/react/24/outline'

import { useCurrentUser } from '@contexts/currentUser'
import TechnicalDifficultiesBanner from './TechnicalDifficultiesBanner'
import MembershipPicker from './MembershipPicker'

import Navbar from './Navbar'

const StudentNav = ({ children }) => {
  const { currentMembership } = useCurrentUser()
  const features = currentMembership?.organization?.features || []

  const navigation = [
    { name: 'Tutors', href: '/students/tutors', icon: ChatBubbleLeftEllipsisIcon }
  ]

  if (features.includes('legacy_features')) {
    navigation.push({ name: 'Courses', href: '/students/courses', icon: BookOpenIcon })
  }

  if (features.includes('tutor_insights')) {
    navigation.push({ name: 'Insights', href: '/students/tutor_insights', icon: LightBulbIcon })
  }

  if (features.includes('student_events')) {
    navigation.push({ name: 'Workshops', href: '/students/workshops', icon: CalendarDaysIcon })
  }

  navigation.push({ name: 'Student join code', href: '/join_short_code', icon: UserPlusIcon })

  const technicalDifficultiesBannerEnabled = features.includes('technical_difficulties_banner')

  return (
    <Navbar
      membershipPicker={<MembershipPicker />}
      navigation={navigation}
      message={technicalDifficultiesBannerEnabled && <TechnicalDifficultiesBanner />}
    >
      {children}
    </Navbar>
  )
}

export default StudentNav
