import { Link } from 'react-router-dom'

import Card from '@components/Card'
import ButtonLink from '@components/ButtonLink'

const EmptyList = () => (
  <Card className="flex flex-col items-center justify-center p-10">
    <p className="text-5xl">🧑‍🎓</p>
    <h4 className="text-2xl font-semibold">No students invited just yet</h4>
  </Card>
)

export default EmptyList
