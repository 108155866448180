import { gql } from 'graphql-request'
import { useMutation } from '@hooks/graphql'

import { useNavigate } from 'react-router-dom'

import OverflowMenu from '@components/OverflowMenu'

const REMOVE_COLLABORATORS_FROM_CLASSROOM = gql`
  mutation removeCollaboratorsFromClassroom($input: RemoveCollaboratorsFromClassroomInput!) {
    removeCollaboratorsFromClassroom(input: $input) {
      success
      errors {
        message
      }
    }
  }
`

const COLLABORATOR_LEAVE_CLASSROOM = gql`
  mutation collaboratorLeaveClassroom($input: LeaveClassroomInput!) {
    collaboratorLeaveClassroom(input: $input) {
      success
      errors {
        message
      }
    }
  }
`

const CollaboratorsListActions = ({ id, classroomId, currentUserIsOwner, isOwner, currentUserIsCollaborator, refetchCollaborators }) => {
  const navigate = useNavigate()

  const { mutate: removeCollaboratorsFromClassroom } = useMutation({
    gqlMutation: REMOVE_COLLABORATORS_FROM_CLASSROOM,
    onSuccess: () => {
      refetchCollaborators()
    }
  })

  const { mutate: collaboratorLeaveClassroom } = useMutation({
    gqlMutation: COLLABORATOR_LEAVE_CLASSROOM,
    onSuccess: () => {
      navigate('/educators/classrooms')
    }
  })

  const removeCollaborator = (id) => {
    removeCollaboratorsFromClassroom({
      input: {
        classroomId,
        collaboratorIds: [id]
      }
    })
  }

  const leaveClassroom = (id) => {
    collaboratorLeaveClassroom({
      input: {
        classroomId,
        educatorId: id
      }
    })
  }

  return (
    <>
      <Choose>
        <When condition={currentUserIsOwner && !isOwner}>
          <OverflowMenu orientation='horizontal'>
            <OverflowMenu.Item>
              <button
                onClick={() => removeCollaborator(id)}
                className='text-md w-full text-left'
              >
                Remove
              </button>
            </OverflowMenu.Item>
          </OverflowMenu>
        </When>

        <When condition={currentUserIsCollaborator && !isOwner}>
          <OverflowMenu orientation='horizontal'>
            <OverflowMenu.Item>
              <button
                onClick={() => leaveClassroom(id)}
                className='text-md w-full text-left'
              >
                Leave classroom
              </button>
            </OverflowMenu.Item>
          </OverflowMenu>
        </When>
      </Choose>
    </>
  )
}

export default CollaboratorsListActions
