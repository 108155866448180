import {
  AcademicCapIcon,
  CalendarDaysIcon,
  ChatBubbleLeftEllipsisIcon,
  UserPlusIcon,
  SparklesIcon,
  BookOpenIcon
} from '@heroicons/react/24/outline'

import { useCurrentUser } from '@contexts/currentUser'
import TechnicalDifficultiesBanner from './TechnicalDifficultiesBanner'

import Navbar from './Navbar'
import UpgradeBanner from '../educators/components/UpgradeBanner'
import OnboardingChecklist from '../educators/components/OnboardingChecklist'
import MembershipPicker from './MembershipPicker'

const EducatorNav = ({ children }) => {
  const { currentMembership: { organization: { features = [] } = {} } = {} } = useCurrentUser()

  const legacyFeaturesEnabled = features.includes('legacy_features')
  const technicalDifficultiesBannerEnabled = features.includes('technical_difficulties_banner')
  const educatorCreatorsEnabled = features.includes('educator_creators')

  const navigation = [
    { name: 'Tutors', href: '/educators/tutors', icon: ChatBubbleLeftEllipsisIcon },
    ...educatorCreatorsEnabled ? [{ name: 'Creators', href: '/educators/creators', icon: SparklesIcon }] : [],
    ...legacyFeaturesEnabled ? [{ name: 'Courses', href: '/educators/courses', icon: BookOpenIcon }] : [],
    { name: 'Classrooms', href: '/educators/classrooms', icon: AcademicCapIcon },
    { name: 'Workshops', href: '/educators/workshops', icon: CalendarDaysIcon },
    { name: 'Invite educators', href: '/educators/organization/educators', icon: UserPlusIcon }
  ]

  return (
    <Navbar
      membershipPicker={<MembershipPicker />}
      navigation={navigation}
      message={
        <>
          <If condition={technicalDifficultiesBannerEnabled}>
            <TechnicalDifficultiesBanner />
          </If>

          <OnboardingChecklist />

          <UpgradeBanner />
        </>
      }
    >
      {children}
    </Navbar>
  )
}

export default EducatorNav
