import { useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { useForm, Controller } from 'react-hook-form'

import { useMutation } from '@hooks/graphql'
import Button from '@components/Button'
import LinkButton from '@components/LinkButton'
import SecureMarkdown from '@components/SecureMarkdown'
import TextEditor from '@components/TextEditor'
import Tooltip from '@components/Tooltip'

const CREATE_REFLECTION_SUBMISSION_MUTATION = gql`
  mutation CreateReflectionSubmission($input: CreateReflectionSubmissionInput!) {
    createReflectionSubmission(input: $input) {
      errors {
        message
      }

      blockSubmission {
        content {
          ... on ReflectionSubmission {
            answer
          }
        }
      }
    }
  }
`

const ReflectionBlock = ({
  blockId,
  submissionId,
  question,
  moveToNextBlock,
  moveToPreviousBlock,
  isFirstBlock,
  isLastBlock,
  openSubmitModal,
  isSubmissionClosed,
  submissionStatus,
  answer
}) => {
  const queryClient = useQueryClient()

  const { mutate: createReflectionSubmission } = useMutation({
    gqlMutation: CREATE_REFLECTION_SUBMISSION_MUTATION,
    onSuccess: () => { queryClient.invalidateQueries('educatorProjectSubmission') }
  })

  const { control, handleSubmit, formState: { errors } } = useForm({
    mode: 'onTouched',
    defaultValues: { answer }
  })

  const submit = data => {
    if (submissionStatus === 'COMPLETED' || submissionStatus === 'RETURNED' || isSubmissionClosed) {
      moveToNextBlock()
    } else if (isLastBlock) {
      createReflectionSubmission({ input: { blockId, educatorProjectSubmissionId: submissionId, ...data } })
      openSubmitModal()
    } else {
      createReflectionSubmission({ input: { blockId, educatorProjectSubmissionId: submissionId, ...data } })
      moveToNextBlock()
    }
  }

  return (
    <>
      <form
        onSubmit={handleSubmit(submit)}
        className='flex flex-col mt-5'
      >
        <SecureMarkdown className='mb-3' content={question} />

        <Choose>
          <When condition={submissionStatus === 'COMPLETED' || submissionStatus === 'RETURNED'}>
            <div className='bg-gray-200 p-3 rounded-lg mt-5'>
              <SecureMarkdown content={answer} />
            </div>
          </When>

          <Otherwise>
            <TextEditor.Container>
              <Controller
                name='answer'
                rules={{
                  required: 'Answer the question to move to the next section'
                }}
                control={control}
                render={({ field }) => (
                  <TextEditor
                    className='min-h-[200px]'
                    {...field}
                  />
                )}
              />

              <TextEditor.Actions>
                <TextEditor.ToolbarToggle />
              </TextEditor.Actions>
            </TextEditor.Container>

            <If condition={errors.answer}>
              <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.answer.message}</p>
            </If>
          </Otherwise>
        </Choose>

        <div className='flex flex-row mt-3 items-center justify-between'>
          <LinkButton
            type='button'
            disabled={isFirstBlock}
            onClick={moveToPreviousBlock}
          >
            back
          </LinkButton>

          <Choose>
            <When condition={isLastBlock}>
              <Button
                type='submit'
                id='submit-button'
                className='self-end'
                disabled={submissionStatus === 'COMPLETED' || submissionStatus === 'RETURNED' || isSubmissionClosed}
                label={(submissionStatus === 'COMPLETED' || submissionStatus === 'RETURNED') ? 'Submitted' : 'Submit'}
              />

              <If condition={isSubmissionClosed}>
                <Tooltip anchorSelect='#submit-button' delayShow={200}>
                  Submissions are closed for this course
                </Tooltip>
              </If>
            </When>

            <Otherwise>
              <Button
                type='submit'
                className='self-end'
                label='Next'
              />
            </Otherwise>
          </Choose>
        </div>
      </form>
    </>
  )
}

export default ReflectionBlock
