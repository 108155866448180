import { useState } from 'react'
import { Link } from 'react-router-dom'
import Button from '@components/Button'
import Table from '@components/Table'

import NoResults from './NoResults'
import InviteEducatorsModal from './InviteEducatorsModal'
import EducatorListActions from './EducatorListActions'

const EducatorList = ({ organizationId, educators, refetch }) => {
  const [showInviteModal, setShowInviteModal] = useState(false)

  return (
    <div className='mx-5 my-3'>
      <div className='flex flex-col w-full pb-10'>
        <Button
          label='Invite educators'
          className='ml-auto mb-3 mr-5 md:mr-0'
          onClick={() => setShowInviteModal(true)}
        />

        <Choose>
          <When condition={educators.length === 0}>
            <NoResults />
          </When>

          <Otherwise>
            <Table>
              <Table.Head>
                <Table.Row>
                  <Table.Header>Name</Table.Header>
                  <Table.Header>Email</Table.Header>
                  <Table.Header>Role</Table.Header>
                  <Table.Header />
                </Table.Row>
              </Table.Head>

              <Table.Body>
                <For each='educator' of={educators}>
                  <Table.Row key={educator.profile.id}>
                    <Table.Cell>
                      <Link className='block' to={`${educator.profile.id}`}>
                        {educator.profile.fullName}
                      </Link>
                    </Table.Cell>
                    <Table.Cell>
                      <Link className='block' to={`${educator.profile.id}`}>
                        {educator.profile.email}
                      </Link>
                    </Table.Cell>
                    <Table.Cell>
                      <Link className='block' to={`${educator.profile.id}`}>
                        {educator.role.toLowerCase()}
                      </Link>
                    </Table.Cell>
                    <Table.Cell className='flex items-center justify-end'>
                      <EducatorListActions
                        id={educator.profile.id}
                        organizationId={organizationId}
                        email={educator.profile.email}
                        fullName={educator.profile.fullName}
                        role={educator.role}
                        refetch={refetch}
                      />
                    </Table.Cell>
                  </Table.Row>
                </For>
              </Table.Body>
            </Table>
          </Otherwise>
        </Choose>
      </div>

      <InviteEducatorsModal
        organizationId={organizationId}
        isOpen={showInviteModal}
        closeModal={() => setShowInviteModal(false)}
      />
    </div>
  )
}

export default EducatorList
