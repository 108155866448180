import { useState } from 'react'
import { gql } from 'graphql-request'
import { useForm } from 'react-hook-form'
import { CheckIcon } from '@heroicons/react/24/outline'

import { useCurrentUser } from '@contexts/currentUser'

import { useMutation } from '@hooks/graphql'
import Button from '@components/Button'
import TextInput from '@components/TextInput'

import ArchiveStudentsModal from './ArchiveStudentsModal'
import ArchiveClassroomModal from './ArchiveClassroomModal'

const UPDATE_CLASSROOM_MUTATION = gql`
  mutation updateClassroom($input: UpdateClassroomInput!) {
    updateClassroom(input: $input) {
      classroom {
        id
        name
      }
      errors {
        message
      }
    }
  }
`

const UNARCHIVE_CLASSROOM_MUTATION = gql`
  mutation unarchiveClassroom($input: UnarchiveClassroomInput!) {
    unarchiveClassroom(input: $input) {
      success
      errors {
        message
      }
    }
  }
`

const Settings = ({ classroomId, name, archived, refetch }) => {
  const [showArchiveStudentsModal, setShowArchiveStudentsModal] = useState(false)
  const [showArchiveClassroomModal, setShowArchiveClassroomModal] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ mode: 'onTouched', defaultValues: { name } })

  const {
    mutate: updateClassroom,
    isLoading: isUpdating,
    isSuccess
  } = useMutation({
    gqlMutation: UPDATE_CLASSROOM_MUTATION,
    onSuccess: () => refetch()
  })

  const {
    mutate: unarchiveClassroom,
    isLoading: isUnarchiving
  } = useMutation({
    gqlMutation: UNARCHIVE_CLASSROOM_MUTATION,
    variables: { input: { classroomId } },
    onSuccess: () => refetch()
  })

  const classroomInitials = name.split(' ').map(word => word[0]).join('').toUpperCase()

  const onSubmit = data => updateClassroom({ input: { ...data, classroomId } })

  const { currentMembership: { role } } = useCurrentUser()

  return (
    <div className='flex gap-5 flex-col sm:flex-row sm:p-5'>
      <div className='flex items-center justify-center h-[200px] sm:rounded-lg sm:w-[300px] bg-blue-100'>
        <h4 className='font-semibold text-4xl'>{classroomInitials}</h4>
      </div>

      <div className='w-full px-5 sm:px-0'>
        <form
          className='flex flex-col'
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className='w-full mb-3'>
            <TextInput
              id='name'
              label='Name'
              {...register('name', { required: 'Name is required' })}
            />
            <If condition={errors.name}>
              <p
                className='mt-1 text-sm font-semibold text-red-500'
                role='alert'
              >
                {errors.name.message}
              </p>
            </If>
          </div>

          <div className='ml-auto'>
            <If condition={isSuccess}>
              <CheckIcon className='mr-2 inline-block h-5 w-5' />
            </If>

            <Button
              className='mb-5 w-fit'
              theme='primary'
              disabled={isUpdating}
              type='submit'
              label={isUpdating ? 'Saving...' : 'Save'}
            />
          </div>
        </form>

        <div className='border border-red-300 rounded-lg mt-5'>
          <If condition={role=='admin'}>
            <div className='p-5 flex flex-row gap-3 items-center justify-between'>
              <div>
                <h4 className='font-semibold'>Archive students</h4>
                <p className='text-sm leading-tight'>
                  Archive all the students in this classroom.
                  Students will no longer be able to access Mindjoy or contribute to the number of active student accounts.
                  This can be changed later.
                </p>
              </div>

              <Button
                onClick={() => setShowArchiveStudentsModal(true)}
                className='shrink-0 w-fit bg-gray-50 hover:bg-gray-100'
                theme='light'
                variant='outlined'
                type='button'
                label='Archive students'
              />
            </div>
          </If>
          <Choose>
            <When condition={archived}>
              <div className='p-5 flex flex-row gap-3 items-center justify-between border-red-300 border-t'>
                <div>
                  <h4 className='font-semibold'>Unarchive classroom</h4>
                  <p className='text-sm leading-tight'>
                    Unarchiving this classroom will restore it to your classsroom list.
                  </p>
                </div>

                <Button
                  onClick={() => unarchiveClassroom()}
                  className='shrink-0 w-fit bg-gray-50 hover:bg-gray-100'
                  theme='light'
                  variant='outlined'
                  type='button'
                  disabled={isUnarchiving}
                  label='Unarchive classroom'
                />
              </div>
            </When>
            <Otherwise>
              <div className='p-5 flex flex-row gap-3 items-center justify-between border-red-300 border-t'>
                <div>
                  <h4 className='font-semibold'>Archive classroom</h4>
                  <p className='text-sm leading-tight'>
                    Archiving this classroom will hide it from view and close all assigned tutor sessions and courses.
                    This can be changed later.
                  </p>
                </div>

                <Button
                  onClick={() => setShowArchiveClassroomModal(true)}
                  className='shrink-0 w-fit bg-gray-50 hover:bg-gray-100'
                  theme='light'
                  variant='outlined'
                  type='button'
                  label='Archive classroom'
                />
              </div>
            </Otherwise>
          </Choose>
        </div>
      </div>

      <ArchiveStudentsModal
        closeModal={() => setShowArchiveStudentsModal(false)}
        isOpen={showArchiveStudentsModal}
        classroomId={classroomId}
      />

      <ArchiveClassroomModal
        closeModal={() => setShowArchiveClassroomModal(false)}
        isOpen={showArchiveClassroomModal}
        classroomId={classroomId}
      />
    </div>
  )
}

export default Settings
