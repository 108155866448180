import React, { useState } from 'react'
import { gql } from 'graphql-request'
import { useParams, useNavigate, Routes, Route, NavLink, useLocation, Link } from 'react-router-dom'
import {
  ArrowUpOnSquareIcon,
  ChatBubbleLeftEllipsisIcon,
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  InformationCircleIcon,
  ShareIcon
} from '@heroicons/react/24/outline'
import { twMerge } from 'tailwind-merge'
import OverflowMenu from '@components/OverflowMenu'

import { useQuery, useMutation } from '@hooks/graphql'
import BreadCrumbs from '@components/Breadcrumbs'
import Button from '@components/Button'
import LinkButton from '@components/LinkButton'

import TutorConversation from './TutorConversation'
import { useSubscription } from '../../hooks/subscription'
import ProFeatureTooltip from '../../components/ProFeatureTooltip'
import AssignModal from './AssignModal'
import ShareModal from './ShareModal'
import Settings from './Settings'

const TUTOR_QUERY = gql`
  query tutor($id: ID!) {
    node(id: $id) {
      ... on Tutor {
        id
        name
        description
        summary
        socratic
        suggestionsEnabled
        stemModeEnabled
        knowledgeBaseEnabled
        visibility
        isOwner
        image {
          url
        }
        documents {
          id
          file {
            url
            filename
          }
        }
        userName
        organizationName
        tags {
          id
          name
        }
      }
    }
  }
`

const CREATE_COPY_MUTATION = gql`
  mutation createCopyOfTutor($input: CreateCopyInput!) {
    createCopyOfTutor(input: $input) {
      tutor {
        id
      }
    }
  }
`

const NavItem = ({ to, state, end = false, label, icon }) => (
  <NavLink
    to={to}
    state={state}
    end={end}
    className={({ isActive }) => twMerge('w-fit ml-5 flex flex-row justify-center items-center px-1 h-full border-b-4 outline-none', isActive ? 'font-semibold border-b-purple-500' : 'border-b-white font-normal ')}
  >
    {icon}
    <span>{label}</span>
  </NavLink>
)

const TutorDetails = () => {
  const { id } = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false)
  const [isShareModalOpen, setIsShareModalOpen] = useState(false)
  const { hasProFeatures } = useSubscription()

  const previousListParams = location.state?.previousListParams || ''

  const { isLoading, refetch, data: { node: tutor = {} } = {} } = useQuery({
    queryKey: ['tutorDetails', id],
    gqlQuery: TUTOR_QUERY,
    variables: { id }
  })

  const { mutate: createCopy, isLoading: isCopying } = useMutation({
    gqlMutation: CREATE_COPY_MUTATION,
    variables: { input: { tutorId: id } },
    onSuccess: data => navigate(`/educators/tutors/${data.createCopyOfTutor.tutor.id}`)
  })

  if (isLoading) return

  const ActionButtonMenu = () => (
    <Choose>
      <When condition={tutor.isOwner}>
        <div className='ml-auto flex items-center'>
          {/* Desktop view */}
          <div className='hidden sm:flex items-center'>
            <LinkButton
              onClick={() => setIsShareModalOpen(true)}
              className='mr-5 text-black'
              theme='light'
            >
              Share
              <ShareIcon className='h-5 w-5 ml-1' />
            </LinkButton>

            <CopyTutorButton
              hasProFeatures={hasProFeatures}
              createCopy={createCopy}
              isCopying={isCopying}
            />

            <Button
              id='assign-tutor-button'
              disabled={!hasProFeatures}
              theme='secondary'
              onClick={() => setIsAssignModalOpen(true)}
              className='pro-feature py-2 sm:mr-5 mr-1 my-1.5 px-3'
              label={<span data-tutorial='assign-tutor-step' className='flex gap-2 items-center'>Assign<ArrowUpOnSquareIcon className='h-6 w-6' /></span>}
            />

            <If condition={!hasProFeatures}>
              <ProFeatureTooltip anchorSelect='#assign-tutor-button' />
            </If>
          </div>

          {/* Mobile view */}
          <div className='sm:hidden flex items-center'>
            <Button
              id='assign-tutor-button-mobile'
              disabled={!hasProFeatures}
              theme='secondary'
              onClick={() => setIsAssignModalOpen(true)}
              className='pro-feature py-2 mr-2 my-1.5 px-3'
              label={<span className='flex gap-2 items-center'>Assign<ArrowUpOnSquareIcon className='h-5 w-5' /></span>}
            />
            <If condition={!hasProFeatures}>
              <ProFeatureTooltip anchorSelect='#assign-tutor-button-mobile' />
            </If>

            <OverflowMenu orientation='vertical' className='mr-3'>

              <OverflowMenu.Item className="w-auto min-w-max">
                <button
                  onClick={() => createCopy()}
                  disabled={!hasProFeatures}
                  className={`text-md text-left flex items-left px-2 py-2 ${!hasProFeatures ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                >
                  <DocumentDuplicateIcon className="h-5 w-5 mr-2" />
                  {isCopying ? "Copying..." : "Make a copy"}
                  <If condition={!hasProFeatures}>
                    <span className="ml-2 inline-flex items-center">
                      (
                      <span className="text-xs text-gray-500 inline-flex items-center">
                        <img
                          className="mr-2 w-4 h-4"
                          src="/crown.svg"
                          alt="crown"
                        />
                        Pro
                      </span>
                      )
                    </span>
                  </If>
                </button>
              </OverflowMenu.Item>
              <OverflowMenu.Item>
                <button
                  onClick={() => setIsShareModalOpen(true)}
                  className='text-md w-full text-left flex items-center px-2 py-2 whitespace-nowrap'
                >
                  <ShareIcon className='h-5 w-5 mr-2' />
                  Share
                </button>
              </OverflowMenu.Item>
            </OverflowMenu>
          </div>
        </div>
      </When>
      <Otherwise>
        <>
          {/* Non-owner view */}
          <div className='ml-auto flex'>
            <CopyTutorButton
              hasProFeatures={hasProFeatures}
              createCopy={createCopy}
              isCopying={isCopying}
              className='ml-auto'
            />
          </div>


        </>
      </Otherwise>
    </Choose>
  )

  return (
    <>
      <BreadCrumbs>
        <BreadCrumbs.Link label='Tutors' to={`/educators/tutors${previousListParams}`} />
        <BreadCrumbs.Text label={tutor.name} />
      </BreadCrumbs>

      <div className='h-[calc(100%-45px)]'>
        <div className='h-[50px] flex flex-row items-center justify-between border-b-gray-200 border-b bg-white'>
          <NavItem
            to=''
            state={{ previousListParams }}
            end
            label='Chat'
            icon={<ChatBubbleLeftEllipsisIcon className='h-6 w-6 mr-1' />}
          />

          <NavItem
            to='settings'
            state={{ previousListParams }}
            icon={<Cog6ToothIcon className='h-6 w-6 mr-1' />}
            label='Settings'
          />

          <Link
            to='/educators/classrooms'
            className='hidden lg:flex bg-gray-200 rounded-full px-2 py-1 ml-5 hover:underline'
          >
            <InformationCircleIcon className='size-4 mr-1' />
            <span className='text-xs'>Student activity has moved to classrooms</span>
          </Link>

          <ActionButtonMenu />
        </div>

        <div className='h-[calc(100%-51px)]'>
          <Routes>
            <Route
              index
              element={
                <TutorConversation
                  tutorId={tutor.id}
                  name={tutor.name}
                  imageUrl={tutor.image?.url}
                />
              }
            />

            <Route
              path='settings'
              element={
                <Settings
                  tutorId={tutor.id}
                  isOwner={tutor.isOwner}
                  name={tutor.name}
                  description={tutor.description}
                  summary={tutor.summary}
                  socratic={tutor.socratic}
                  stemModeEnabled={tutor.stemModeEnabled}
                  knowledgeBaseEnabled={tutor.knowledgeBaseEnabled}
                  suggestionsEnabled={tutor.suggestionsEnabled}
                  visibility={tutor.visibility}
                  refetch={refetch}
                  imageUrl={tutor.image?.url}
                  documents={tutor.documents}
                  tagIds={tutor.tags?.map(tag => tag.id)}
                />
              }
            />
          </Routes>
        </div>
      </div>

      <AssignModal
        isOpen={isAssignModalOpen}
        closeModal={() => setIsAssignModalOpen(false)}
        name={tutor.name}
        tutorId={tutor.id}
        refetch={refetch}
      />

      <ShareModal
        isOpen={isShareModalOpen}
        closeModal={() => setIsShareModalOpen(false)}
        tutorId={tutor.id}
        name={tutor.name}
        summary={tutor.summary}
        visibility={tutor.visibility}
      />
    </>
  )
}

const CopyTutorButton = ({ hasProFeatures, createCopy, isCopying, className = '' }) => (
  <>
    <Button
      id='copy-tutor-button'
      disabled={!hasProFeatures}
      onClick={() => createCopy()}
      className={`pro-feature shrink-0 py-2 sm:mr-5 mr-1 my-1.5 px-3 ${className}`}
      label={isCopying ? 'Copying...' : <span className='flex gap-2 items-center'>Make a copy<DocumentDuplicateIcon className='h-6 w-6' /></span>}
    />

    <If condition={!hasProFeatures}>
      <ProFeatureTooltip anchorSelect='#copy-tutor-button' />
    </If>
  </>
)

export default TutorDetails
